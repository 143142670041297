import React from "react";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Contact</h2>
          <p>
            Have questions, inquiries, or ready to start your packaging journey?
            Reach out to our friendly team today! Whether you're seeking expert
            advice, requesting a quote, or exploring partnership opportunities,
            we're here to assist you every step of the way. Contact us via
            phone, email, or fill out the form below, and let's bring your
            packaging vision to life together.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-12 d-flex align-items-stretch">
            <div className="info">
              <div className="address">
                <i className="bi bi-geo-alt" />
                <h4>Location:</h4>
                <p>
                  Aji GIDC Estate, Shed No C1B-237/2 Road U, Rajkot, Gujarat
                </p>
              </div>
              <div className="email">
                <i className="bi bi-envelope" />
                <h4>Email:</h4>
                <p>shreeshrink@gmail.com</p>
              </div>
              <div className="phone">
                <i className="bi bi-phone" />
                <h4>Call:</h4>
                <p>
                  +91 98981 11003
                  <br />
                  +91 93774 55500
                </p>
              </div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3691.934364743661!2d70.820858!3d22.280475999999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjLCsDE2JzQ5LjciTiA3MMKwNDknMTUuMSJF!5e0!3m2!1sen!2sin!4v1711817574727!5m2!1sen!2sin"
                frameBorder={0}
                style={{ border: 0, width: "100%", height: 290 }}
                allowFullScreen=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
